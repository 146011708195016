import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getHomesApi,
  addHomeApi,
  deleteHomeApi,
  getHomeDetailsApi,
} from "../services/api/homes";

const initialState = { homes: [], activeHome: null };

export const getHomesAsync = createAsyncThunk("homes/getHomes", async () => {
  try {
    const response = await getHomesApi();
    if (response.status !== 200) {
      throw new Error("Get homes failed");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const getHomeDetailsAsync = createAsyncThunk(
  "homes/getHomeDetails",
  async (home_id) => {
    try {
      const response = await getHomeDetailsApi(home_id);
      if (response.status !== 200) {
        throw new Error("Get home details failed");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addHomeAsync = createAsyncThunk(
  "homes/addHome",
  async (formData) => {
    try {
      const response = await addHomeApi(formData);
      if (response.status !== 200) {
        throw new Error("Add home failed");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteHomeAsync = createAsyncThunk(
  "homes/deleteHome",
  async (formData) => {
    try {
      const response = await deleteHomeApi(formData);
      if (response.status !== 200) {
        throw new Error("Delete home failed");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const homesSlice = createSlice({
  name: "homes",
  initialState,
  reducers: {
    setActiveHome: (state, action) => {
      state.activeHome = action.payload;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomesAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHomesAsync.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.error = null;
        state.loading = false;
      })
      .addCase(getHomesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addHomeAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addHomeAsync.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.error = null;
        state.loading = false;
      })
      .addCase(addHomeAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteHomeAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteHomeAsync.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.error = null;
        state.loading = false;
      })
      .addCase(deleteHomeAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getHomeDetailsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHomeDetailsAsync.fulfilled, (state, action) => {
        state.selectedHome = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(getHomeDetailsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setActiveHome, setLoading, setError } = homesSlice.actions;
export default homesSlice.reducer;
