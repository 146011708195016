import { startLoading, endLoading, setError } from "./statusSlice";

const statusMiddleware = (store) => (next) => (action) => {
  const { dispatch } = store;

  if (action.type.endsWith("/pending")) {
    dispatch(startLoading(action.type));
  }

  if (action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected")) {
    dispatch(endLoading(action.type));

    if (action.error) {
      dispatch(
        setError({ actionType: action.type, error: action.error.message })
      );
    }
  }

  return next(action);
};

export default statusMiddleware;
