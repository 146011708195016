import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginApi,
  cookieLoginApi,
  logoutApi,
  signupApi,
} from "../services/api/auth";

export const loginUser = createAsyncThunk("auth/login", async (credentials) => {
  try {
    const response = await loginApi(credentials);
    if (response.status !== 200) {
      throw new Error("Invalid credentials");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const cookieLogin = createAsyncThunk("auth/cookieLogin", async () => {
  try {
    const response = await cookieLoginApi();
    if (response.status !== 200) {
      throw new Error("Invalid credentials");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const logoutUser = createAsyncThunk("auth/logout", async () => {
  try {
    const response = await logoutApi();
    if (response.status !== 200) {
      throw new Error("Invalid credentials");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const signupUser = createAsyncThunk("auth/signup", async (formData) => {
  try {
    const response = await signupApi(formData);
    if (response.status !== 200) {
      throw new Error("Invalid credentials");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  account: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.account = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(cookieLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cookieLogin.fulfilled, (state, action) => {
        state.account = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(cookieLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.account = null;
        state.loading = false;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.account = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setLoading, setError } = authSlice.actions;
export default authSlice.reducer;
