import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cookieLogin } from "./redux/authSlice";
import EnvironmentBanner from "./components/EnvironmentBanner";
import { NODE_ENV_ENUM } from "./constants";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import "./App.css";

const AppNav = React.lazy(() => import("./navigation/AppNav"));
const AuthNav = React.lazy(() => import("./navigation/AuthNav"));

export default function App() {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { account } = authState;
  const { loading, errors } = useSelector((state) => state.status);

  useEffect(() => {
    dispatch(cookieLogin());
  }, [dispatch]);

  const isCookieLoginLoading = loading["auth/cookieLogin"];
  if (isCookieLoginLoading) {
    return <Loading />;
  }

  const error = errors && errors[0] && errors[0].message;

  return (
    <Router>
      <div className="root">
        {process.env.NODE_ENV !== NODE_ENV_ENUM.PRODUCTION && (
          <EnvironmentBanner />
        )}
        <React.Suspense fallback={<Loading />}>
          {error && <div className="error-message">{error}</div>}
          <div className="content">{account ? <AppNav /> : <AuthNav />}</div>
        </React.Suspense>
        <Footer />
      </div>
    </Router>
  );
}
