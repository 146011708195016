import axios from "axios";
import { NODE_ENV_ENUM } from "../constants";

let host = process.env.REACT_APP_API_HOST_DEVELOPMENT;

if (process.env.REACT_APP_ENV === NODE_ENV_ENUM.PRODUCTION) {
  host = process.env.REACT_APP_API_HOST_PRODUCTION;
} else if (process.env.REACT_APP_ENV === NODE_ENV_ENUM.STAGING) {
  host = process.env.REACT_APP_API_HOST_STAGING;
} else {
  host = process.env.REACT_APP_API_HOST_DEVELOPMENT;
}

// axios configuration
const axiosInstance = axios.create({
  baseURL: host + "/v1/",
  withCredentials: true,
});

export const getRequest = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("API request failed");
  }
};

export const postRequest = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("API request failed");
  }
};

export const putRequest = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("API request failed");
  }
};

export const deleteRequest = async (url, data) => {
  try {
    const response = await axiosInstance.delete(url, data);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("API request failed");
  }
};

export default axiosInstance;
