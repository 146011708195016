import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateProfileApi, getProfileApi } from "../services/api/user";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  profile_image_url: "",
};

export const updateProfileAsync = createAsyncThunk(
  "users/updateProfile",
  async (profileData) => {
    try {
      const response = await updateProfileApi(profileData);
      if (response.status !== 200) {
        throw new Error("Update profile failed");
      }
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProfileAsync = createAsyncThunk(
  "users/getProfile",
  async () => {
    try {
      const response = await getProfileApi();
      if (response.status !== 200) {
        throw new Error("Get profile failed");
      }
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.first_name = action.payload;
      state.error = null;
    },
    setLastName: (state, action) => {
      state.last_name = action.payload;
      state.error = null;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
      state.error = null;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
      state.error = null;
    },
    setProfileImageUrl: (state, action) => {
      state.profile_image_url = action.payload;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfileAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
      })
      .addCase(updateProfileAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProfileAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.error = null;
        state.loading = false;
      })
      .addCase(getProfileAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setProfileImageUrl,
  setLoading,
  setError,
} = profileSlice.actions;
export default profileSlice.reducer;
