import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import profileReducer from "./profileSlice";
import statusReducer from "./statusSlice";
import statusMiddleware from "./statusMiddleware";
import homesReducer from "./homesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    status: statusReducer,
    homes: homesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(statusMiddleware),
});
