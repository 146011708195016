import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2", // A shade of blue
      light: "#63A4FF",
      dark: "#004BA0",
      contrastText: "#fff", // White text for better readability on blue background
    },
    secondary: {
      main: "#29B6F6", // Another shade of blue, but lighter
      light: "#6FF9FF",
      dark: "#0086C3",
    },
    background: {
      default: "#E3F2FD", // Light blue background
      paper: "#fff", // White background for elevated surfaces
    },
    text: {
      primary: "#333", // Slightly off-black for main text
      secondary: "#555", // A bit lighter shade for secondary text
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
  },
});

export default theme;
