import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: {},
  errors: {},
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading[action.payload] = true;
    },
    endLoading: (state, action) => {
      state.loading[action.payload] = false;
    },
    setError: (state, action) => {
      state.errors[action.payload.actionType] = action.payload.error;
    },
    clearError: (state, action) => {
      delete state.errors[action.payload];
    },
  },
});

export const { startLoading, endLoading, setError, clearError } =
  statusSlice.actions;
export default statusSlice.reducer;
