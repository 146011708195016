import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <span>
          © {currentYear}{" "}
          <a href="https://securedai.co/" style={styles.link}>
            Secured AI Inc
          </a>
          , a{" "}
          <a href="https://heltoncompanies.com/" style={styles.link}>
            Helton Company
          </a>
        </span>
        <div style={styles.links}>
          <a href="https://securedai.co/privacy-policy" style={styles.link}>
            Privacy Policy
          </a>
          <a
            href="https://securedai.co/terms-and-conditions"
            style={styles.link}
          >
            Terms & Conditions
          </a>
          <a href="https://support.securedai.co" style={styles.link}>
            Support
          </a>
        </div>
      </div>
    </footer>
  );
}

const styles = {
  footer: {
    width: "100%",
    padding: "20px 0",
    backgroundColor: "#f7f7f7",
    borderTop: "1px solid #e4e4e4",
    position: "relative",
    bottom: 0,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 20px",
  },
  links: {
    display: "flex",
    gap: "20px",
  },
  link: {
    textDecoration: "none",
    color: "#333",
    transition: "color 0.3s",
    "&:hover": {
      color: "#007BFF",
    },
  },
};

export default Footer;
